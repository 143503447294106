.midashi1 {
  padding-top: 30px;
  position: relative;
  margin: 0;
  &::before {
    content: "";
    display: block;
    width: 45px;
    height: 2px;
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
  }
  &::after {
    content: "";
    display: block;
    width: 15px;
    height: 2px;
    background-color: $info;
    position: absolute;
    top: 0;
    left: 45px;
  }
}
