.balloon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;

  .balloon-text {
    background-color: #fff100;
    border-radius: 10px;
    padding: $spacer;
    position: relative;
    flex: 1;

    p {
      &:last-child {
        margin: 0;
      }
    }

    @include media-breakpoint-up(sm) {
      max-width: 60%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      right: -10px; // 三角を右側に移動
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 10px;
      border-color: transparent transparent transparent #fff100;
    }
  }

  .balloon-author {
    width: 60px;
    height: 60px;
    border: 3px solid #fff100;
    border-radius: 50%;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      width: 80px;
      height: 80px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}