.searchform {
  .form-control {
  }
  position: relative;
  &::after {
    position: absolute;
    right: 15px;
    top: 13px;
    font-family: "Font Awesome 5 Pro";
    content: "\f002";
    opacity: 0.5;
    pointer-events: none;
  }
}
