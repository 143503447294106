.projectArchive {
  .permalink {
    text-decoration: none;
    color: $body-color;
    display: grid;
    grid-template:
      "img ... header"
      "... ... ....." 10px
      "description description description"
      / 30% 10px 1fr;
    @include media-breakpoint-up(sm) {
      padding: 10px;
      background-color: $gray-100;
      grid-template:
        "img ... ...." 1fr
        "img ... header"
        "img ... ....." 10px
        "img ... description"
        "img ... ...." 1fr
        / 30% 15px 1fr;
    }
    .img {
      grid-area: img;
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(3 / 4 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
        @include media-breakpoint-up(sm) {
          padding-top: calc(630 / 1200 * 100%); //高さの比率 ÷ 幅の比率 × 100
        }
      }
      &::after {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        color: $white;
        font-family: "Font Awesome 5 Pro";
        content: "\f144";
      }
      img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $transition-base;
      }
    }
    .header {
      grid-area: header;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .title {
        color: $body-color;
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }
      }
    }
    .description {
      grid-area: description;
      color: $secondary;
      margin: 0;
      font-size: 12px;
      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }
  }
}
