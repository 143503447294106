.articleHeader {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  .title {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 2px solid;
    margin: 0;
    line-height: 1.5;
    font-size: 27px;

    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }

  .data {
    display: flex;
    align-items: center;

    .post-categories {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
      gap: 5px;

      li {
        a {
          padding: 3px 15px;
          background-color: $body-color;
          color: $white;
          text-decoration: none;
          transition: $transition-base;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius;

          &:hover {
            color: $body-color;
            background-color: $white;
          }
        }
      }
    }

    .date {
      font-weight: bold;
      margin-right: 1rem;
      color: $body-color;
      font-size: 12px;
    }
  }

  .author {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    gap: 10px;
    color: $body-color;
    text-decoration: none;
    transition: $transition-base;

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      transition: $transition-base;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {}

    &:hover {
      color: $primary;

      .icon {
        transform: scale(1.1);

        img {}
      }
    }
  }
}

.articleBody {
  max-width: 800px;
  margin: 0 auto;
  $cms-space: 30px;
  font-size: 15px;
  padding-bottom: $cms-space * 3;
  font-size: 15px;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  .leadArea {
    font-size: 18px;
  }

  .tags {
    margin-bottom: $cms-space;
    color: $secondary;
    display: flex;
    justify-content: flex-start;

    a {
      display: flex;
      color: $secondary;
      text-decoration: none;
      transition: $transition-base;
      margin-right: 15px;

      &::before {
        content: "#";
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .balloon {
    margin: $cms-space * 1.5 0;
  }

  .article-cta {
    margin-top: $cms-space * 4;
  }

  h2,
  h3,
  h4,
  h5 {
    line-height: 1.5;
    margin-bottom: $cms-space;
    font-weight: bold;
  }

  >h2 {
    padding-bottom: 15px;
    border-bottom: 2px solid;
    margin-top: $cms-space * 4;
  }

  >h3 {
    padding-left: 20px;
    border-left: 4px solid;
    margin-top: $cms-space * 2;
  }

  >h4 {
    margin-top: $cms-space * 1.5;
    margin-bottom: $cms-space * 0.5;
  }

  >p {
    margin-bottom: $cms-space;
    // line-height: 2;
    line-height: 2;
    letter-spacing: 0.04em;
  }

  ul,
  ol {
    margin-bottom: $cms-space;
    line-height: 1.75;
  }

  .wp-block-image {
    margin-bottom: $cms-space;
  }

  .wp-block-quote {
    margin-bottom: $cms-space;
    opacity: 0.75;
    font-style: italic;
    border-left: 3px solid;
    line-height: 1.75;
    font-size: 115%;
  }

  .wp-block-separator.is-style-dots {
    margin-bottom: $cms-space * 2;
    margin-top: $cms-space * 2;

    &:before {
      content: "＊＊＊";
      letter-spacing: 0.5em;
      padding-left: 0.5em;
    }
  }
}

.articleFooter {
  max-width: 800px;
  margin: 0 auto;

  .sns {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1px;

    li {
      flex: 1;

      a {
        background-color: $secondary;
        color: $white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 30px 5px;
        text-decoration: none;
        transition: $transition-base;
        @include fontSize(12);

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          font-size: 15px;
        }

        &.facebook {
          background-color: #3b5998;
        }

        &.twitter {
          background-color: #0F1419;
        }

        &.line {
          background-color: #07b53b;
        }

        i {
          font-size: 24px;
        }

        &:hover {
          background-color: $body-color;
        }
      }
    }
  }
}