.recommend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    color: $body-color;
    text-decoration: none;
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(630 / 1200 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $transition-base;
      }
    }
    .cont {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      transition: $transition-base;
      .title {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }
      .data {
        margin: 0;
        font-size: 12px;
      }
    }
    &:hover {
      .img {
        img {
          transform: scale(1.05);
        }
      }
      .cont {
        color: $primary;
      }
    }
  }
}
