.blogArchive {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template:
    "permalink" 1fr
    "..." 12px
    "association" auto
    / 1fr;
  .permalink {
    grid-area: permalink;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: $body-color;
    text-decoration: none;
    transition: $transition-base;
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      .more {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: $body-color, $alpha: 0.75);
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        opacity: 0;
        transition: $transition-base;
        .inner {
          clip-path: inset(100% 0 0 0);
          transition: all 0.6s ease-in-out 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1em;
          padding-bottom: 2px;
          border-bottom: 1px solid;
          &::after {
            font-family: "Font Awesome 5 Pro";
            content: "\f178";
            font-weight: 400;
          }
        }
      }
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(630 / 1200 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $transition-base;
      }
    }
    .cont {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 9px;
      .date {
        font-size: 12px;
        line-height: 1;
        font-weight: bold;
        opacity: 0.6;
      }
      .title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.25;
      }
    }
    &:hover {
      color: $primary;
      .img {
        .more {
          opacity: 1;
          .inner {
            clip-path: inset(0 0);
          }
        }
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  .association {
    grid-area: association;
    padding: 10px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $body-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px 20px;
    .post-categories {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 10px;
      gap: 5px;
      li {
        a {
          padding: 3px 15px;
          background-color: $body-color;
          color: $white;
          text-decoration: none;
          transition: $transition-base;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius;
          &:hover {
            color: $body-color;
            background-color: $white;
          }
        }
      }
    }
    .author {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
      font-weight: bold;
      gap: 10px;
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        transition: $transition-base;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .name {
      }
      &:hover {
        color: $primary;
        .icon {
          transform: scale(1.1);
          img {
          }
        }
      }
    }
  }
}
