.tagCloud {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 12px;
  li {
    margin: 0 5px 5px 0;
    a {
      color: $body-color;
      text-decoration: none;
      display: inline-block;
      padding: 5px;
      border: 1px solid;
      border-radius: $border-radius;
      transition: $transition-base;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &::before {
        content: "#";
      }
    }
  }
}
