#toc_container {
  border: 1px solid $body-color;
  padding: 30px 15px;
  width: 100% !important;
  margin-top: 4rem;
  @include media-breakpoint-up(sm) {
    padding: 45px;
  }
  .toc_title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
    .toc_toggle {
      font-weight: normal;
      a {
        color: $body-color;
        text-decoration: none;
      }
    }
  }
  .toc_list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 15px;
    margin-top: 10px;
    @include media-breakpoint-up(sm) {
      margin-top: 20px;
    }
    li {
      margin: 5px 0;
      @include media-breakpoint-up(sm) {
        margin: 10px 0;
      }
      a {
        color: $body-color;
        text-decoration: none;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        padding-left: 1em;
        li {
          margin: 5px 0;
          @include media-breakpoint-up(sm) {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
