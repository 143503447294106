.widelayout {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
}
.projectSection {
  padding: 45px 0;
  @include media-breakpoint-up(sm) {
    padding: 60px 0;
  }
}
.brandContainer {
  padding: 0 6vw;
  max-width: calc(1200px + 12vw);
  margin: 0 auto;
}
.brandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  max-width: calc(800px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
}
.magazineSection {
  padding: 45px 0;
  @include media-breakpoint-up(sm) {
    padding: 75px 0;
  }
}
.magazineCont {
  max-width: calc(1200px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
  .sideCont {
    margin-top: 60px;
    @include media-breakpoint-up(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 6vw;
    .mainCont {
      flex: 1;
      max-width: 800px;
    }
    .sideCont {
      width: 280px;
    }
  }
}
.bg-gray {
  background-color: $gray-100;
}
