@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

#app {
  position: relative;

  #footer,
  main {
    position: relative;
    z-index: 2;
  }

  .bgMovie {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    opacity: 0.75;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}

@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}

@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1280 * 100vw);
}

@mixin ippaiFont($length: 10) {
  font-size: calc((100vw - 12vw) / $length);
}

.marker {
  background: linear-gradient(transparent 60%, rgba($warning, 0.5) 60% 90%);
}

@import "components/header";
@import "components/toggleMenu";
@import "components/heroArea";
@import "components/heroArea2";
@import "components/ctaForm";
@import "components/sectionArea";
@import "components/keyVisual";
@import "components/layout";
@import "components/planCard";
@import "components/blogArchive";
@import "components/summary";
@import "components/outline";
@import "components/digest";
@import "components/digestCover";
@import "components/article";
@import "components/projectArchive";
@import "components/projectThumbnail";
@import "components/projectSummary";
@import "components/pagination";
@import "components/toc";
@import "components/calling";
@import "components/recommend";
@import "components/profile";
@import "components/searchform";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/formTable";
@import "components/brandTable";
@import "components/flow";
@import "components/faq";
@import "components/newsList";
@import "components/newsBar";
@import "components/newsArchive";
@import "components/catchCopyBar";
@import "components/catchCopyBorder";
@import "components/anchorLinks";
@import "components/btn-cta";
@import "components/outlineCard";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/balloon";
@import "components/article-cta";
@import "components/footer";
@import "components/vif";