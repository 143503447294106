$headerHeight: 80px;
$headerHeightSM: 100px;
#header {
  width: 100%;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  position: relative;
  z-index: 3;
  @include media-breakpoint-up(sm) {
    height: $headerHeightSM;
    padding: 15px 30px;
  }
  .logo {
    img {
      height: 30px;
      @include media-breakpoint-up(sm) {
        height: 36px;
      }
    }
  }
}
