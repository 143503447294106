.midashi2 {
  padding-left: 20px;
  position: relative;
  margin: 0;
  &::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 50%;
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 50%;
    background-color: $info;
    position: absolute;
    top: 50%;
    left: 0;
  }
}
