.heroArea {
  width: 100vw;
  height: 100vh;
  margin-top: calc(#{$headerHeight} * -1);
  position: relative;
  z-index: 1;
  padding: $headerHeight 6vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include media-breakpoint-up(sm) {
    margin-top: calc(#{$headerHeightSM} * -1);
    padding: $headerHeightSM 6vw;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .cont {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    .catchCopy {
      margin: 0;
      @include ippaiFont(9);
      line-height: 1.25;
      @include media-breakpoint-up(lg) {
        @include ippaiFont(12);
      }
    }
    .leadCopy {
      margin: 0;
      font-size: 15px;
      // font-weight: 700;
      line-height: 1.75;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 21px;
      }
      strong {
        background-color: rgba($warning, 0.75);
        // color: $white;
      }
    }
  }
  .circle {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 120px;
    height: 120px;
    color: $danger;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    @include media-breakpoint-up(sm) {
      bottom: 30px;
      right: 30px;
    }
  }
}
