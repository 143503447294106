.midashi3 {
  border-bottom: 1px solid;
  padding-bottom: 15px;
  text-align: center;
  font-size: 21px;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    font-size: 24px;
  }
}
