.catchCopyBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin: 0;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 0;
  }
  span {
    padding: 15px 0;
    background: $body-color;
    color: $white;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 60px;
    }
  }
  &.center {
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
}
