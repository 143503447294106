.heroArea2 {
  margin-top: $headerHeight * -1;
  width: 100%;
  position: relative;
  @include media-breakpoint-up(sm) {
    margin-top: $headerHeightSM * -1;
  }
  > .bg {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(4 / 4 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include media-breakpoint-up(lg) {
      height: 100vh;
      &::before {
        padding: 0;
      }
    }
  }
  &.bnr {
    margin: 0;
    > .bg {
      &::before {
        padding-top: 250px;
        @include media-breakpoint-up(sm) {
          height: 500px;
        }
      }
      @include media-breakpoint-up(lg) {
        height: 500px;
      }
    }
  }
  > .cont {
    position: relative;
    z-index: 2;
    padding: 45px 6vw;
    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      .catchCopy {
        font-size: 24px;
        line-height: 1.5;
        font-weight: 900;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 45px;
        }
      }
      .leadCopy {
        font-size: 15px;
        line-height: 1.75;
        font-weight: bold;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
    }
  }
}
