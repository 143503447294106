.calling {
  display: flex;
  flex-direction: column;
  gap: 30px;
  > .header {
    @include media-breakpoint-up(sm) {
    }
    > .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
      color: $primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      &::before {
        content: "";
        width: 45px;
        height: 2px;
        background-color: $primary;
      }
      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    gap: 18px;
    @include media-breakpoint-up(sm) {
    }
    > .img {
      img {
        border-radius: $border-radius;
      }
    }
    > .text {
      margin: 0;
      color: $secondary;
      line-height: 1.75;
    }
  }
}
