.sectionArea {
  padding: 18vw 6vw;
  border-top: 1px solid $border-color;
  @include media-breakpoint-up(sm) {
    padding: 9vw 6vw;
  }
  @include media-breakpoint-up(lg) {
    padding: 7.5vw 6vw;
  }
  .cont {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .articleBody {
      padding: 0;
    }
    @include media-breakpoint-up(lg) {
      gap: 60px;
    }
    > .header {
      flex-basis: 100%;
      @include media-breakpoint-up(lg) {
        flex-basis: 40%;
      }
    }
    > .body {
      flex-basis: 100%;
      @include media-breakpoint-up(lg) {
        flex-basis: calc(60% - 60px);
        padding: 15px 0;
      }
    }
    > .centering {
      flex-basis: 100%;
      width: 100%;
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
    }
  }
  .catchCopy {
    font-size: 36px;
    line-height: 1.5;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 60px;
    }
  }
  .leadCopy {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 1.75;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 21px;
    }
  }
}
