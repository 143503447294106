.keyVisual {
  width: 100%;
  overflow: hidden;
  padding-left: 6vw;
  max-height: 50vh;
  @include media-breakpoint-up(sm) {
    padding: 0 6vw;
    text-align: center;
    max-height: 65vh;
  }
}
