#footer {
  padding: 30px 6vw;
  border-top: 1px solid $border-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .fnav {
    font-size: 12px;
    color: $secondary;
    flex-basis: 100%;
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      li {
        a {
          color: $secondary;
        }
      }
    }
  }
  .copyright {
    font-size: 12px;
    color: $secondary;
    flex-basis: 100%;
    @include media-breakpoint-up(sm) {
      flex: 1;
      text-align: right;
    }
  }
}
