.article-cta {
  background-image: url(../img/bg_article-cta.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  padding: $spacer * 2 $spacer;

  @include media-breakpoint-up(sm) {
    text-align: center;
    padding: $spacer * 3 $spacer * 2;
  }

  .title {
    font-size: 24px;
    margin-bottom: $spacer;

    @include media-breakpoint-up(sm) {
      font-size: 30px;
    }
  }

  .text {
    line-height: 1.75;
    margin-bottom: $spacer * 1.5;
  }

  .cta-btn {
    text-align: center;
  }
}