.planCard {
  background-color: rgba($color: $primary, $alpha: 0.1);
  padding: 30px 10px;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: calc(50% - 22.5px);
    content: "";
    width: 45px;
    height: 2px;
    background-color: $primary;
  }
  @include media-breakpoint-up(sm) {
    padding: 45px 30px;
  }
  @include media-breakpoint-up(lg) {
    padding: 60px 45px;
  }
  > .title {
    font-size: 18px;
    text-align: center;
    margin: 0;
    color: $primary;
    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
  }
  > .price {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    margin: 0;
    margin-top: 10px;
    color: $primary;
    small {
      font-size: 60%;
    }
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
  }
  > .img {
    margin: 0;
    margin-top: 20px;
    text-align: center;
  }
  > .cont {
    margin: 0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .text {
      margin: 0;
    }
  }
  > .more {
    margin-top: 20px;
  }
}
