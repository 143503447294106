.projectSummary {
  padding: 30px 0 45px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    padding: 60px 0;
    gap: 30px;
  }
  > .cont {
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
    > .title {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 24px;
      @include media-breakpoint-up(sm) {
        font-size: 30px;
      }
    }
    .articleBody {
      padding: 0;
      font-size: 15px;
    }
  }
  > .cta {
    @include media-breakpoint-up(sm) {
      flex-basis: 200px;
    }
  }
}
