.profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid $body-color;
  padding: 30px 15px;
  gap: 30px;
  @include media-breakpoint-up(sm) {
    padding: 45px;
    flex-direction: row;
    align-items: flex-start;
  }
  .header {
    .icon {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      @include media-breakpoint-up(sm) {
        width: 150px;
        height: 150px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    @include media-breakpoint-up(sm) {
      flex: 1;
      align-items: flex-start;
    }
    .name {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
    }
    .text {
      font-size: 14px;
      line-height: 1.75;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
